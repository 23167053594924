
$white:  #fff;
$black:  #000;
$red:    #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500;
$green:  #405f40;
$blue:   #0275d8;
$teal:   #5bc0de;
$pink:   #ff5b77;
$purple: #613d7c;
$cyan:   #17a2b8;

// Create grayscale
$gray:                      #345360;
$gray-100:                  #6eb6b4;

$theme-colors: (
    primary: $gray,
    secondary: $gray,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray,
    dark: $gray
  );



// Body
//
// Settings for the `<body>` element.

$body-bg:       $white;
$body-color:    $gray;
$inverse-bg:    $gray;
$inverse-color: $white;


// Links
//
// Style anchor elements.

$link-color:            $gray;
$link-decoration:       none;
$link-hover-color:      $gray-100;
$link-hover-decoration: underline;
