/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */

/* build upon bootstrap */
// @import "../../../node_modules/bootstrap/scss/variables";
@import "variables";
@import "glyphicons";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import '../../../node_modules/react-datepicker/dist/react-datepicker';

div {
  position: relative;
}
.w-50 {
  width: 50%;
}
@media (max-height: 700px) {
  .nav-link {padding: .3em 1em;}
}

@media (min-width: 768px) {
  .layout-right.col-md-8 {
      margin-left: 33.33333%;
  }
}
@media (min-width: 992px) {
  .layout-right.col-lg-9 {
      margin-left: 25%;
  }
}

.jumbotron {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0;
  padding-bottom: 50%;
  margin-bottom: 2rem;
  overflow:hidden; 
}
.home .jumbotron {
  margin-bottom: 0;
}
.jumbotron .container {
  position: absolute;
}

.bg-cookie-detail,
.bg-cookie-banner {
  background-color: #add8e6;
}

h1 {
  width:95%;
  padding-top: 15%;
	padding-bottom: 1rem;
	border-bottom: 1px solid #eee;
	margin-bottom: 2rem;
}
@include media-breakpoint-down(md) {
  h1 {
    padding-top: 5%;
  }
}
@include media-breakpoint-down(sm) {
  h1 {
    padding-top: 1%;
    margin-bottom: 0;
  }
}
.video-modal .modal-dialog {
  max-width: inherit;
  width: 95vw;
  height: 90vh;
}
@include media-breakpoint-down(sm) {
  .video-modal .modal-dialog {
    width: 95vw;
    height: 95vw;
  }
}
.modal-title {
  line-height: 1;
  margin: 0;
  font-size: 1.2rem;
}
.modal-content {
  height: 100%;
  width: 100%;
}
.modal-body {
  position: relative;
}
.modal-body iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
h4 {
  margin: 1rem 0;
  padding: .6rem;
  width: 99%;
}
.single-block {width: 95%; margin: 0 auto; text-align: left;}
.grid-block .col {width: 22rem;vertical-align:top; margin: 0 1.5rem;}
@include media-breakpoint-down(sm) {
  .grid-block .col {margin: 0;}
}

.img-box {
  position: relative;
  width: 99%;
  overflow: hidden;
}
.img-box.square {
  padding-bottom: 99%; /* used to create square */ 
}
.img-box .img-wrap {
  position: absolute;
  height:100%;
  width:100%;
  left: 50%;
}
.img-box .img-full {
  position: relative;
  margin-left: -50%;
  width: 100%;
}
.img-box .img-center {
  position: absolute;
  top: 50%;
  max-width: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-box {
  padding:.2rem;
}
.text-box ul {
  padding-left: 1.5rem;
}
.text-box small > * {
  display: none;
}
.text-box small > *:nth-child(-n+2){
    display: block;
}
.text-box small.more > * {
  display: block;
}
.blockquote {
  font-size: .9rem;
}
.toggle {
  font-size: .6rem;
}
.caret-down {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.caret-up {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.reserve {
  background: #f0f0f0;
}
.calendar {
  z-index: 1000;
  position: absolute;
  left: 33%;
  background: #f0f0f0;
}
.lectors .img-box{
  margin-top: -1rem;
  margin-bottom: 1em;
}
/*.lightbox {
  position: fixed;
}*/
.gallery .img-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 100%;
}
.gallery .img-wrapper img {
  position: absolute;
  left:0;
  top:0;
  max-width: 100%;
  max-height: 100%;
}
.nele-toolbar { background: #ddd; border-bottom: solid 1px #000; margin-bottom: 5px;}
.nele-toolbar > button { margin: 5px;}

.ngx-datatable .datatable-body-row:hover {
  background-color: $inverse-bg;
  color: $inverse-color;
  -webkit-transition-property: background;
  transition-property: background;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}